<template>
  <b-card v-if="data">
    <b-row class="pb-50">

      <!-- text and button -->
      <b-col
        sm="6"
        order-sm="1"
        order="2"
        class="d-flex justify-content-between flex-column mt-1 mt-sm-0"
      >
        <div>
          <h2 class="font-weight-bolder mb-25">
            Monitoring Penanganan Lanjutan
          </h2>
          <b-card-text class="font-weight-bold mb-2">
            Aplikasi Pelaporan Online
          </b-card-text>
        </div>
      </b-col>

      <!-- dropdown and chart -->
      
      <b-col
        sm="12"
        cols="12"
        order-sm="2"
        order="1"
        class="d-flex justify-content-between flex-column text-right"
      >
      <div>
          <b-dropdown
            text="Pilih Tahun"
            variant="transparent"
            class="chart-dropdown"
            left
            no-caret
            size="sm"
          >
            <b-dropdown-item
              v-for="day in lastDays"
              :key="day"
            >
              {{ day }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
      <b-col
        sm="7"
        cols="12"
        order-sm="2"
        order="1"
        class="d-flex justify-content-between flex-column text-right"
      >

        <!-- apex chart -->
        <vue-apex-charts
          type="bar"
          height="380"
          :options="BarChart.chartOptions"
          :series="BarChart.series"
        />
      </b-col>
      <b-col
        sm="5"
        cols="12"
        order-sm="2"
        order="1"
        class="d-flex justify-content-between flex-column text-right"
      >
        <!-- apex chart -->
        <vue-apex-charts
          type="donut"
          height="380"
          :options="DonutChart.chartOptions"
          :series="DonutChart.series"
        />
      </b-col>
    </b-row>
    <hr>
    <!-- progress bar -->
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BDropdown, BDropdownItem, BProgress, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      lastDays: [2021, 2022, 2023],
      BarChart : {

        series: [{
          data: [400, 430, 448, 470, 540, 580]
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 380
          },
          plotOptions: {
            bar: {
              barHeight: '100%',
              distributed: true,
              horizontal: true,
              dataLabels: {
                position: 'bottom'
              },
            }
          },
          colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f'
          ],
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['#fff']
            },
            formatter: function (val, opt) {
              return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
            dropShadow: {
              enabled: true
            }
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          xaxis: {
            categories: ['Analisis', 'LPP', 'Daftar Sidang Panel', 'LHP', 'Konsep PSP', 'Daftar Sidang Pleno'
            ],
          },
          yaxis: {
            labels: {
              show: false
            }
          },
          title: {
              text: 'Jumlah Penanganan Lanjutan',
              align: 'center',
              floating: true
          },
          subtitle: {
              text: 'Berdasarkan Status',
              align: 'center',
          },
          tooltip: {
            theme: 'dark',
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return ''
                }
              }
            }
          }
        },
      },
      DonutChart: {
        series: [400, 430, 448, 470, 540, 580],
        chartOptions: {
          chart: {
            type: 'donut',
          },
          labels: ['Analisis', 'LPP', 'Daftar Sidang Panel', 'LHP', 'Konsep PSP', 'Daftar Sidang Pleno'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom',
              }
            }
          }]
        },
      },
    }
  },
  methods: {
    kFormatter,
  },
}
</script>
